import React from "react";
import image from "../../assets/images/image-1.jpg";
const WatTemple = () => {
  return (
    <React.Fragment>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Angkor Wat Temple Ticket</h3>
          <div className="contant">
            <h4>Angkor Wat</h4>
            <p align="justify" className="floatL">
              <span className="floatR marLeft20">
                <img src={image} width={600} />
              </span>
              The most historical site present in Southeast Asia is the Angkor.
              It is located in Siem Reap’s northern province, Cambodia. Spread
              over almost 400 square km consists of several temples, basins,
              dykes, reservoirs, and canals, these all are hydraulic structures
              and routes for communication.
              <br />
              For the Khmer kingdom, Angkor had been a center for many
              centuries. Angkor wat was initially designed and constructed in
              the first half of the 12 th century, during the reign of
              Suryavarman II (ruled 1113 – c. 1150), as the king’s state temple
              and capital city. It was built without the aid of any machinery,
              as there was no machinery available at that time. The construction
              of Angkor Wat is thought to have taken 35 years, 300,000 laborers,
              and 6000 elephants, according to inscriptions.
              <br />
              <br />
              This site has features of exceptional civilization like impressive
              monuments, several different ancient urban plans, and large water
              reservoirs.
              <br />
              At present also, most of the population has converted to
              Buddhists, which plays an important role in Cambodia. The number
              of tourists has risen considerably over the past decade. After
              extensive restoration work due to damage, deterioration, and
              natural elements taking their toll, such as plant growth and
              ground movements from earthquakes, the monument is one of the most
              visited in Cambodia. In 2013, the number of visitors to the site
              reached over two million and continues to grow today. Projects
              have been introduced to preserve the monuments as much as possible
              in line with the growing number of tourists. Ropes and wooden
              steps have been installed to aid wear and tear on the low-reliefs
              and floors in particular. Buddhist monks and nuns worship at the
              site.
              <br />
              It was listed as a UNESCO World Heritage Site in 1992, which
              encouraged an international effort to save the complex.
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </React.Fragment>
  );
};

export default WatTemple;
