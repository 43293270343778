import React, { useState, useEffect } from "react";

import { commonApiCall } from "../../service/api";
import Adduser from "./Adduser";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Pagination } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const Users = () => {
  const navigate = useNavigate();
  const [userData, setuserData] = useState();
  const [formOpen, setformOpen] = useState(false);
  const [index, setindex] = useState(0);
  const [totalRecord, setTotalRecord] = useState(0);
  const [loading, setLoading] = useState(false);
  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
  });

  const pageChange = (no) => {
    if (no !== params.pageNo) {
      setParams({ ...params, pageNo: no });
    }
  };

  const previousPage = () => {
    setParams({ ...params, pageNo: params.pageNo - 1 });
  };

  const nextPage = () => {
    setParams({ ...params, pageNo: params.pageNo + 1 });
  };

  const serialize = (obj) => {
    var str = [];
    for (var p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
      }
    return str.join("&");
  };

  // console.log(index)

  const getUsers = async () => {
    setLoading(true);
    try {
      let response = await commonApiCall(
        "api/admin?" + serialize(params),
        "get"
      );

      if (response.status === 200) {
        setuserData(response.records);
        setTotalRecord(response.totalRecords);
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
    // response.then((result) => setuserData(result.result));
  };

  useEffect(() => {
    getUsers();
  }, [params]);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <React.Fragment>
      {/* <button onClick={()=>{setUserBody({user_id:28});}}>please click me</button>  */}
      <div className="main-wrapper mt-3">
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="page-header">
              <div className="row align-items-center">
                <div className="col"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 d-flex">
                <div className="card card-table flex-fill">
                  <div className="card-header">
                    <h4 className="card-title float-start">Admin List</h4>
                    <div className="table-search float-end">
                      {/* <input type="text" className="form-control" placeholder="Search" />
      <button className="btn" type="submit"><i className="fa fa-search" /></button> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <React.Fragment>
                      <div className="table-responsive no-radius">
                        <table className="table table-hover table-center mb-0 user-list">
                          <thead>
                            <tr>
                              <th>Sr No.</th>
                              <th>Temporary Id</th>
                              <th>Full Name</th>
                              <th>Mobile No.</th>
                              <th>Email </th>
                              <th>type</th>

                              <th>Date of Entry</th>
                              <th>Date of Expire</th>
                              <th>Datetime</th>
                              <th>Currency</th>

                              <th>Rate</th>
                              <th>Amount</th>
                              {/* <th>city Code</th> */}
                              <th className="text-end">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {userData?.map((val, i) => (
                              <tr>
                                <td>{i + 1}</td>
                                <td>{val?.temporary_id}</td>
                                <td style={{ textTransform: "capitalize" }}>
                                  {val?.first_name + " " + val?.last_name}
                                </td>
                                {/* <h6 style={{"textDecoration":"none"}}>
                          <a href="general.html" className="avatar avatar-sm me-2"><img className="avatar-img rounded-circle" src="assets/img/profiles/avatar-03.jpg" alt="User Image" /></a>
                          <a href="general.html">{val?.first_name +" "+ val?.last_name}</a>
                        </h6> */}

                                <td>{val?.mobile}</td>
                                {/* <td>{i}</td> */}
                                <td>{val?.email} </td>
                                <td>{val?.type}</td>

                                <th>
                                  {moment(val?.date_of_entry).format(
                                    "DD/MM/YYYY"
                                  )}
                                </th>
                                <th>
                                  {" "}
                                  {moment(val?.date_of_expiry).format(
                                    "DD/MM/YYYY"
                                  )}
                                </th>
                                <td>
                                  {" "}
                                  {moment
                                    .utc(val?.datetime)
                                    .format("DD/MM/YYYY hh:mm:ss a")}
                                </td>
                                <td>{val?.currency}</td>
                                <td>{val?.rate}</td>
                                <td>{val?.amount}</td>
                                <td>
                                  <span
                                    className={
                                      val?.payment_status === "success"
                                        ? "text-success"
                                        : ""
                                    }
                                  >
                                    {val?.payment_status}
                                  </span>
                                </td>
                                {/* <td>{val?.amount}</td> */}
                                {/* <td>{val?.city_code}</td> */}
                                <td className="text-end">
                                  <div className="actions">
                                    {/* <a href="#" onClick={()=>{}} className="btn btn-sm bg-success-light me-2"> */}
                                    {/* <i className="fe fe-pencil" /> */}
                                    <RemoveRedEyeIcon
                                      onClick={() => {
                                        window.open(
                                          "/client-portal/preview?tran_id=" +
                                            val?.tran_id,
                                          "",
                                          `width=${
                                            window.innerWidth / 1
                                          },height=500`
                                        );
                                      }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="float-end d-flex align-items-center mt-3">
                        {loading && (
                          <span className="me-3">Processing Data...</span>
                        )}

                        <div className="custom-pagination d-flex mb-3 justify-content-end">
                          <button
                            className="btn btn-link"
                            onClick={previousPage}
                          >
                            <i className="fa-solid fa-chevron-left"></i>
                          </button>
                          <input
                            className="form-control w-25"
                            type={"number"}
                            onChange={(e) =>
                              pageChange(parseInt(e.target.value))
                            }
                            value={params.pageNo}
                          />
                          <button className="btn btn-link" onClick={nextPage}>
                            <i className="fa-solid fa-chevron-right"></i>
                          </button>
                        </div>
                        {/* <Pagination>
                          {[
                            ...Array(Math.ceil(totalRecord / params.pageSize)),
                          ].map((_, index) => {
                            return (
                              <Pagination.Item
                                disabled={loading}
                                onClick={() => pageChange(index + 1)}
                                active={params.pageNo === index + 1}
                              >
                                {index + 1}
                              </Pagination.Item>
                            );
                          })}
                        </Pagination> */}
                      </div>
                    </React.Fragment>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Users;
