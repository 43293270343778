import React from "react";

const RefundAndCancellation = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3> Refund & Cancellation</h3>
          <div className="contant">
            <h4>Refund & Cancellation</h4>
           <p><a href="https://www.angkorenterprise.org/" target="_blank">www.angkorenterprise.org</a> gives it's users a chance to apply for refund /cancellation in case the applicants want to  discontinue with our services subject to the processing of the application from our side. The request for the refund/cancellation will not be entertained if the tickets has already issued from the concerned authority.</p>
           <p>A) No refund/cancellation in case the visit is within 24 hours.</p>
           <p> If you wish to request a refund, email us at <a href="mailto:info@angkorenterprises.org">info@angkorenterprises.org</a> indicating the following Your reason for the request.</p>
           <ul>
            <li>Your full names (as appears in your application).</li>
            <li>Your unique transaction ID.</li>
            <li>The email used to make the registration on this website.</li>
            <li>All refund requests will be processed within 72 hours.</li>
           </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundAndCancellation;
