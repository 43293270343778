import React from "react";
import image from "../../assets/images/Phnom-Bakheng-temple.jpg";

const BakhengTemple = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Bakheng Temple Ticket</h3>
          <div className="contant">
            <h4>Bakheng Temple</h4>
            <p align="justify" className="floatL">
              <span className="floatR marLeft20">
                <img src={image} width={600} />
              </span>
              The most historical site present in The temple, Phnom Bakheng,
              which is situated in Angkor, Cambodia is in a form of a temple
              mountain which is a Hindu and Buddhist temple. It was built by the
              end of the 9th century during the reign of king Yasovarman
              (889-910) and is dedicated to Lord Shiva. It has become a popular
              tourist spot as it is located at the top of the hill, people enjoy
              the sunsets and bigger views of Angkor wat temple. Bakheng temple
              was built at least 2 centuries before the Angkor wat temple so it
              is considered the major and principal temple of the Angkor region.
              When king Yasovarman moved his court from the capital
              Hariharalayain the Roluos area in the southeast, the temple was
              the architectural centerpiece of the new capital Yashodharapura.{" "}
              <br />
              <br />
              There was an inscription that was found at the Sdok Kak Thom
              temple in present-day Thailand, dated 1052 AD, states in Sanskrit,
              “When Sri Yasovardhana became king under the name of Yasovarman,
              the able Vamasiva continued as his guru. By the king's order, he
              set up a linga on Sri Yasodharagiri, a mountain equal in beauty to
              the king of mountains." It is believed by historians or scholars
              that this passage refers to the consecration of the Phnom Bakheng
              temple, a century and a half earlier approx.
              <br />
              <br />
              This temple Phnom Bakheng is among the top three hilltop temples
              dedicated to Yasovarman’s reign in the Angkor area. There are two
              others namely Phnom Krom to the south near the Tonle Sap Lake, and
              Phnom Bok, northeast of the East Baray reservoir.
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </>
  );
};

export default BakhengTemple;
