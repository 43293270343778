import React from "react";
import { Link, Outlet } from "react-router-dom";
import Banner from "./banner";

const TempleMenu = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div
          className="contentBox"
          style={{ width: "100%", float: "left", display: "block" }}
        >
          <h3>Temples</h3>
          <div className="contant">
            <div className="ul-1">
              <ul>
                <li>
                  <a href="/temples/wattemple">Angkor Wat</a>
                  {/* <a style={{ color: "#00a650" }}> */}

                  {/* </a> */}
                </li>
                <li>
                  <a href="/temples/bayontemple"> Bayon</a>
                  {/* <a style={{ color: "#00a650" }}>
                   
                  </a> */}
                </li>
                <li>
                  <a href="/temples/bakhengtemple">Bakheng Temple</a>
                  {/* <a style={{ color: "#00a650" }}>
                    Bakheng Temple
                  </a> */}
                </li>
                <li>
                  <a href="/temples/baphuontemple">Baphuon Temple</a>
                  {/* <a style={{ color: "#00a650" }}>
                    
                  </a> */}
                </li>
                <li>
                  <a href="/temples/banteaysritemple">Banteay Srei Temple</a>
                  {/* <a style={{ color: "#00a650" }}>
                    
                  </a> */}
                </li>
                <li>
                  <a href="/temples/preahkhantemple"> Preah Khan Temple</a>
                  {/* <a  style={{ color: "#00a650" }}>
                 
                  </a> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TempleMenu;
