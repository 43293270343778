import React, { useState } from "react";

import { Button, Card, Container, FloatingLabel, Form } from "react-bootstrap";
import { commonApiCall, showErrorToast } from "../../service/api";
// import { API_LOCAL } from "../config";

const Login = () => {
  //  const history=useHistory()
  const [formData, setFormData] = useState({
    mobile: "",
    otp: "",
  });
  const [showOtp, setShowOtp] = useState(false);

  const adminLogin = async () => {
    try {
      let response = await commonApiCall("auth/login", "post", {
        mobile: formData.mobile,
      });
      if (response.status === 200 && response.result.valid) {
        setShowOtp(true);
      }
    } catch (err) {
      alert(err);
    }
  };

  const verifyOtp = async () => {
    try {
      let response = await commonApiCall("auth/token", "post", {
        mobile: formData.mobile,
        otp: formData.otp,
      });
      if (response.status === 200) {
        localStorage.setItem("adminAuth", response.result.valid);
        window.location.reload();
      }
    } catch (err) {
      alert(err);
    }
  };

  //  const apiCall=async()=>{
  //   try{
  //      const data={mobile:mobile}
  //   await axios.post(API_LOCAL+"/auth/login",data).
  //   then(response=>{
  //     // console.log(response)
  //     sessionStorage.setItem("mobile",JSON.stringify( response?.data?.result?.mobile));
  //     sessionStorage.setItem("full_name",JSON.stringify( response?.data?.result?.full_name));
  //     sessionStorage.setItem("user_id",JSON.stringify( response?.data?.result?.user_id));
  //     props.handleLoginData(response?.data?.result)
  //     if(response?.data?.result?.valid){
  //       alert("You Have Successfully Register Mobile number")
  //      history.push("/otp")
  //     }else
  //     alert("Please Enter Valid Registered Mobile Number")
  //   }).catch (err=>{
  //    console.log(err)
  //   })}catch (error){console.log(error)}

  //  }

  return (
    <div className="admin-login-container">
      <Card>
        <Card.Body>
          <img src="/images/logo.png" className="w-50 mx-auto" />
          <h3 className="my-4 fw-bold text-center">Admin Login</h3>
          <FloatingLabel
            controlId="floatingInput"
            label="Mobile Number"
            className="mb-3"
          >
            <Form.Control
              value={formData.mobile}
              type="number"
              placeholder="eg:1234567890"
              onChange={(e) =>
                setFormData({ ...formData, mobile: e.target.value })
              }
            />
          </FloatingLabel>
          {showOtp && (
            <FloatingLabel
              controlId="floatingInput"
              label="OTP"
              className="mb-3"
            >
              <Form.Control
                value={formData.otp}
                type="number"
                placeholder="OTP"
                onChange={(e) =>
                  setFormData({ ...formData, otp: e.target.value })
                }
              />
            </FloatingLabel>
          )}
          <Button
            disabled={
              formData.mobile === "" ||
              formData.mobile.length !== 10 ||
              (showOtp && formData.otp === "")
            }
            className="w-100"
            variant="dark"
            onClick={showOtp ? verifyOtp : adminLogin}
          >
            Login
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Login;
