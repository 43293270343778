import React, { useState } from "react";

import { Link, Outlet } from "react-router-dom";
import TempleMenu from "../../layout/templeMenu";

const AllTemple = () => {
  return (
    <>
      <TempleMenu />
      <Outlet />
    </>
  );
};

export default AllTemple;
