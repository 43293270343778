import moment from "moment";
import React, { useState, useEffect } from "react";
import image1 from "../../assets/images/angkor-wat.jpg";
import { commonApiCall } from "../../service/api";
import { BASE_URL, LOCAL_BASE_URL } from "../../service/config";
import FileSaver from "file-saver";

const Adduser = (props) => {
  const __DEV__ = document.domain === "localhost";
  const visitorId = new URLSearchParams(window.location.search).get("tran_id");
  const [visitor, setvisitor] = useState([]);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);

  const getVisitor = async () => {
    // setLoading(true);

    setLoading(true);
    const data = { tran_id: visitorId };
    // const data = { tran_id:76};
    try {
      let response = await commonApiCall("api/visitor", "post", data);

      if (response.status === 200) {
        setFormData(response.data);
        setvisitor(response?.visitorList);
        // setvisitor(response.records);
        // setTotalRecord(response.totalRecords);
      }
    } catch (err) {
      alert(err);
    }
    setLoading(false);
    // setLoading(false);
    // response.then((result) => setvisitor(result.result));
  };

  function fetchImageAndDownload(e, fileUrl, download) {
    e.preventDefault(); // Prevent browser's default download stuff...

    FileSaver.saveAs(fileUrl, download);
  }

  useEffect(() => {
    getVisitor();
  }, []);

  return (
    <div className="main-wrapper login-body mt-4">
      {!loading ? (
        <div className="container">
          {" "}
          <div className="row">
            <div className="col-md-3">
              <div className="form-group">
                <p>Temporary ID:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.temporary_id}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p>First Name:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.first_name}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p>Last Name:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.last_name}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p>Mobile No:</p>
                <input
                  disabled
                  className="form-control"
                  type="text"
                  value={"+" + formData.country_code + "-" + formData?.mobile}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p>Email:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.email}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <p>Type:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.type}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group ">
                <p>Note:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.note}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group ">
                <p>IP Address:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.ip_address}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group ">
                <p>IP Address Country:</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.ip_address_country}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group ">
                <p>Currency</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData?.currency}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group ">
                <p>Rate</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData.rate}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group ">
                <p>Amount</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={formData.amount}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group ">
                <p>Date Of Entry</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={moment(formData.date_of_entry).format("DD/MM/YYYY")}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group ">
                <p>Date Of Expiry</p>
                <input
                  className="form-control"
                  type="text"
                  disabled
                  value={moment(formData.date_of_expiry).format("DD/MM/YYYY")}
                />
              </div>
            </div>
          </div>
          <h4 className="mt-4">Visitor List</h4>
          <table className="table table-responsive table-bordered">
            <thead>
              <tr>
                <td>Gender</td>
                <td>Nationality</td>
                <td>Group Age</td>
                <td>Image</td>
              </tr>
            </thead>
            <tbody>
              {visitor.length > 0 &&
                visitor.map((visit, index) => {
                  return (
                    <tr key={index}>
                      <td>{visit.gender}</td>
                      <td>{visit.nationality}</td>
                      <td>{visit.group_age}</td>
                      <td>
                        <a
                          href={
                            (__DEV__ ? LOCAL_BASE_URL : BASE_URL) +
                            "images/" +
                            visit.image_path
                          }
                          onClick={(e) =>
                            fetchImageAndDownload(
                              e,
                              (__DEV__ ? LOCAL_BASE_URL : BASE_URL) +
                                "images/" +
                                visit.image_path,
                              visit.image_path
                            )
                          }
                          download={visit.image_path}
                        >
                          Download image
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="form-group text-end">
            <button className="btn btn-primary" onClick={() => window.close()}>
              back to list
            </button>
          </div>
        </div>
      ) : (
        <div className="loading-main">
          <h3>Processing Data...</h3>
          <p>This will take few minutes</p>
        </div>
      )}

      {/* <br /> */}

      {/* <br /> */}

      {/* <div className="form-group ">
        <p>Gender:</p>
        <input
          className="form-control"
          type="text"
          disabled
          value={visitor.gender}
        />
      </div>
      <div className="form-group ">
        <p>Date Of Entry</p>
        <input
          className="form-control"
          type="text"
          disabled
          value={visitor.date_of_entry}
        />
      </div>
      <div className="form-group ">
        <p>Date Of Expiry</p>
        <input
          className="form-control"
          type="text"
          disabled
          value={visitor.date_of_expiry}
        />
      </div> */}

      {/* <br /> */}
    </div>
  );
};

export default Adduser;
