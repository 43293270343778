import React from "react";

const TermAndCondition = () => {
  return (
    <>
      <div className="centerCol">
        {/* / welcome box \ */}
        <div className="contentBox">
          <h3>Angkor Wat Temple Ticket</h3>
          <div className="contant">
            <h4>TERMS AND CONDITIONS</h4>
            <p align="justify">
              Reading all the terms &amp; conditions is very important. This is
              an agreement that is related to the payment procedure between you
              (user) and the{" "}
              <a
                href="https://angkorenterprise.org/"
                className="text-decoration-none"
              >
                angkorenterprise.org
              </a>
            </p>
            <h4>INTRODUCTION</h4>
            As a user, you would be guided through these terms and conditions
            for what you are legally entitled to expect from the company to
            purchase the tickets online while using the website.
            <br />
            <br />
            <h4>DEFINITIONS</h4>
            Wherever the terms “we”, “us”, and “our” are used, they are for the
            company{" "}
            <a
              href="https://angkorenterprise.org/"
              className="text-decoration-none"
            >
              angkorenterprise.org
            </a>{" "}
            and other services providers that manage the website.
            <br />
            <br />
            And the terms “you”, “your”, and “yours” refer to the user who is
            visiting the website to purchase the tickets online.
            <br />
            <br />
            Other common words used in the terms and conditions are:
            <br />
            <br />
            “Purchasing” - when a client buys a ticket online. The order of that
            purchase is done from the company’s website angkortickets.com
            <br />
            <br />
            “Customer” or “client” – someone who uses the website to obtain the
            service provided by the company to buy the tickets online using
            their registered account that is confirmed by the company.
            <br />
            <br />
            “Website” -{" "}
            <a
              href="https://angkorenterprise.org/"
              className="text-decoration-none"
            >
              angkorenterprise.org
            </a>
            <br />
            <br />
            <h4>BUYING TICKETS ONLINE THROUGH THE WEBSITE</h4>
            Terms and Conditions that you are abiding to agree if you buy
            tickets online through the website are given below:
            <br />
            <br />
             You agree to all the payment-related terms and conditions.
            <br />
            <br />
             You are under the obligation to agree to contact the company first
            if you face any problem related to your purchasing for solutions.
            <br />
            <br />
             The amount you paid for the tickets successfully, you should agree
            that it will be non- refundable.
            <br />
            <br />
             The activities you do on the website, you will be responsible for
            all.
            <br />
            <br />
             The tickets you buy and whoever uses your account, you understand
            that you are financially responsible for all activities.
            <br />
            <br />
            <h4>CONTRACT</h4>
             Our company, for the ANGKOR Pass, acts as an intermediary for all
            the clients who purchase the tickets online. As an agent, we provide
            quality.
            <br />
            <br />
            <h4>PRICE AND PAYMENTS</h4> <strong>PRICE</strong>
            <br />
            <br />
            When you reach the final step of purchasing the tickets, then the
            prices are confirmed. You have to click on the checkout button on
            the payment page if it is on the website. <br />
            <br />
            At the last step of the order, the complete price of tickets will be
            shown in USD currency.
            <br />
            <br />
            Sometimes it happens that the amount you pay is different from what
            is shown on the webpage of the site. As it can add some charges or
            feesdepending on the exchange rate applied to process the
            transaction.
            <br />
            <br />
            <strong> PAYMENTS</strong>
            There are several options through which you can make the payment for
            the online tickets. Provide your bank credit or debit card details
            as required to pay the complete price.
            <br />
            <br />
            As soon as you complete the payment, you will receive a payment
            receipt and your ticket to your registered email ID sent
            automatically.
            <br />
            <br />
            Still, if you find any error or any issue regarding your payment,
            you can contact the company angkortickets.com, and we will solve the
            problem conveniently.
            <br />
            <br />
            <h4>NECESSARY CONDITIONS</h4>
            <strong>VISITORS AND TICKET LIMITATIONS</strong>
            <br />
            <br />
            The citizens of Cambodia cannot buy tickets online either through a
            website.
            <br />
            <br />
            Only visitors of age 12 years at least or above can buy the tickets
            online.
            <br />
            <br />
            A visitor can buy 5 tickets maximum on a single purchase.
            <br />
            <br />
            <strong>PRICE</strong>
            <p>
              {" "}
              The ticket type decides the price of a particular ticketeach time
              you buy one.
            </p>
            There are no extra charges over the prices of a selected ticket.
            Once the payment is done, you will receive the payment confirmation
            on your email ID.
            <br />
            <br />
            <strong>E-TICKETS</strong>
            <p>
              {" "}
              These are electronically generated tickets bought either from the
              website. They are generated in the form of PDF format so it
              becomes easy for you to download, take a printout, or use anyhow.
            </p>
            This E-Ticket is asked to be shown at the entrance point for
            checking purposes.
            <br />
            <br />
            {/* <h4>COPYRIGHT ON WEBSITE</h4>
      All content present on our website and mobile application whether text, logos, icons, images, etc. is our property or allow to use, and free content from non-profit organizations.<br /><br /> */}
            <h4>THIRD-PARTY SITE</h4>
            There is a link to the website directly in the content and it also
            redirects you to “contact us”, the option is present on the home
            page or the login page of the website.
            <p />
            <h4>Tickets Prices:</h4>
            <p>
              1 Days Angkor Wat Temple Fee - USD 45 (37 Ticket Prices + USD 4
              International Transaction Fee +4 Processing Fees = 45)
            </p>{" "}
            <p>
              {" "}
              3 Days Angkor Wat Temple Fee - USD 72 (62 Ticket Prices + USD 4
              International Transaction Fee + 6 Processing Fees = 72)
            </p>{" "}
            <p>
              {" "}
              5 Days Angkor Wat Temple Fee - USD 82 (82 Ticket Prices + USD 4
              International Transaction Fee + 6 Processing Fees = 82)
            </p>{" "}
            <p>
              {" "}
              1 Day Kohker Temple Fee - USD 20 (15 Ticket Prices + USD 2
              International Transaction Fess + 3 Processing Fees = 20)
            </p>
          </div>
        </div>
        {/* / welcome box \ */}
      </div>
    </>
  );
};

export default TermAndCondition;
